import React from 'react'
import styled from 'styled-components'

import amandaSrc from 'assets/amanda_hunter.jpg'

export default function Testamonial() {
  return (
    <Wrapper className="my-20 -mx-0 py-8 px-10 bg-white shadow-lg rounded-3xl flex items-center">
      <div className="flex-1 text-md">
        “Mark really captured the vision for our organization. He is a great
        communicator, and made the whole process very easy. I have nothing but
        good things to say!”
      </div>
      <Bio className="flex items-center">
        <img
          src={amandaSrc}
          alt="Amanda"
          className="rounded-full h-24 w-24 ml-6 mr-4"
        />
        <div className="">
          Amanda
          <div className="text-sm italic whitespace-nowrap">LEAP Charities</div>
        </div>
      </Bio>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const Bio = styled.div`
  @media (max-width: 600px) {
    margin-top: 20px;
  }

  img {
    @media (max-width: 600px) {
      margin-left: 0;
    }
  }
`
