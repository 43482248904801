import React from 'react'
import styled from 'styled-components'
import {
  CodeIcon,
  SparklesIcon,
  ShieldCheckIcon,
  TemplateIcon,
} from '@heroicons/react/outline'

import CheckmarkItem from './CheckmarkItem'

export default function CheckmarksSection() {
  return (
    <div>
      <h3 className="text-3xl">If you need:</h3>
      <List className="my-8 w-4/5">
        <CheckmarkItem Icon={CodeIcon}>
          A developer with over 10 years of experience
        </CheckmarkItem>
        <CheckmarkItem Icon={TemplateIcon}>
          A freelance Shopify developer experienced in guiding businesses
          through the complete process of setting up and customizing Shopify
          stores
        </CheckmarkItem>
        <CheckmarkItem Icon={SparklesIcon}>
          A developer with technical skill and an eye for design, detail, and
          polish
        </CheckmarkItem>
        <CheckmarkItem Icon={ShieldCheckIcon}>
          A professional, reliable software developer with proactive
          communication and a proven track record
        </CheckmarkItem>
      </List>
      <h3 className="text-2xl">You&apos;re in the right place.</h3>
    </div>
  )
}

const List = styled.ul`
  @media (max-width: 600px) {
    width: 100%;
  }
`
