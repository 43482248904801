import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import shopifyGrayscale from 'assets/shopify-grayscale.png'

ServicesItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default function ServicesItem({ title, description }) {
  return (
    <Wrapper>
      <Image src={shopifyGrayscale} />
      <Text>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  position: relative;
`

const Image = styled.img`
  max-width: 80px;
  flex-shrink: 0;
  opacity: 0.3;

  @media (max-width: 600px) {
    /* display: none; */
    position: absolute;
    opacity: 0.15;
    top: -20px;
  }
`

const Text = styled.div`
  margin-left: 20px;
`

const Title = styled.div`
  font-size: 28px;
  font-weight: 900;

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

const Description = styled.div`
  font-size: 17px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`
