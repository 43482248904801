import React from 'react'
import styled from 'styled-components'

import profileImg from 'assets/mark_main_profile.png'

export default function Hero() {
  return (
    <div className="py-10 sm:py-20 lg:py-32">
      <Img src={profileImg} />

      <div>
        <Title>Looking for a Freelance Shopify Developer?</Title>

        <div className="text-base sm:text-lg lg:text-xl">
          <Bio className="mt-5 leading-8">
            Hi, I&apos;m Mark- a freelance Shopify developer and software
            engineer. I help build, launch, and optimize Shopify stores for
            businesses who want the best.{' '}
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </Bio>
        </div>
      </div>

      <a
        href="/contact"
        className="bg-bmc-teal text-white px-6 py-3 mt-6 rounded-md hover:text-white inline-block"
      >
        Hire Me
      </a>
    </div>
  )
}

const Img = styled.img`
  height: 240px;
  width: 240px;
  flex-shrink: 0;
  margin-left: -15px;

  @media (max-width: 600px) {
    height: 180px;
    width: 180px;
  }
`

const Title = styled.h1`
  text-align: left;
  max-width: 36rem;
  margin-top: 0px;
  line-height: 3.5rem;
  font-size: 3rem;

  @media (max-width: 600px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media (max-width: 400px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`

const Bio = styled.p`
  @media (max-width: 600px) {
    line-height: 1.5rem;
  }
  @media (max-width: 400px) {
    line-height: 1.3rem;
  }
`
