import React from 'react'

import ServicesItem from './ServicesItem'

export default function ServicesSection() {
  return (
    <div className="my-20">
      <h2 className="text-5xl">Services</h2>
      <ul className="my-10">
        <ServicesItem
          title="Shopify Development"
          description="Full-stack Shopify development, including initial site planning, builds, and maintenance."
        />
        <ServicesItem
          title="Theme Maintenance & Customization"
          description="Shopify theme development and customization, implemented quickly and following the latest coding standards and best practices."
        />
        <ServicesItem
          title="Shopify Coaching &amp; Consulting"
          description="Technical reviews and conversion optimization to help you get the most out of your store."
        />
      </ul>
    </div>
  )
}
